import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePhoneBookList() {
  const toast = useToast();
  const refListTable = ref(null);
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: false },
    { key: 'company_name',label:'Company', sortable: false },
    { key: 'contact_name',label:'Contact Person', sortable: false },
    { key: 'phone',label:'Phone Number', sortable: false },
    { key: 'email',label:'Email', sortable: false },
    { key: `service`,label:'Service', sortable: false },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totals = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const dataMeta = computed(() => {
  const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  });

  const fetchData = (ctx, callback)=>{
    const offset = perPage.value*(currentPage.value-1);

    store.dispatch('app-phone-book/fetchData', {
      perpage: perPage.value,
      offset: offset,
      order_by: 'id DESC'
    })
    .then(response => {
      const phone = response.data.data;
      totals.value = response.data.total
      callback(phone)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: "Error fetching PhoneBook' list",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const deleteData = (id) => {
    store.dispatch('app-phone-book/deletePhoneBook', id).then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      refetchData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete Blog",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  return {
    fetchData,
    tableColumns,
    perPage,
    currentPage,
    totals,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    deleteData,
    refetchData,
  }
}