import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                .post('/phonebook/v1/lists', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        addPhoneBook(ctx, data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/phonebook/v1/add', data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updatePhoneBook(ctx, data){
            return new Promise((resolve, reject) => {
                axios
                    .post(`/phonebook/v1/update/${data.id}`, data.data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        deletePhoneBook(ctx, id){
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/phonebook/v1/delete/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        addPhoneBookService(ctx, data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/phonebook/v1/service/add', data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        fetchPhonebookServices(ctx, data){
            return new Promise((resolve, reject) => {
                axios
                .post('/phonebook/v1/service/lists', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        updatePhoneBookService(ctx, data){
            return new Promise((resolve, reject) => {
                axios
                    .post(`/phonebook/v1/service/update/${data.id}`, data.data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        detelePhoneBookService(ctx, id){
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/phonebook/v1/service/delete/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}